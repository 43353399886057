<template>
    <div class="o-layout o-layout--medium">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <recess-button
                        :title="BUTTON_TEXT.goBack"
                        icon="back"
                        variant="variant4"
                        class="qa-go-back-button"
                        url="/result"
                    />
                    <title-text :title="PROVIDER.ResultsAndContractsPage.ContractCostTypesPage.DetailsTitle" class="qa-provider-settings-title" />
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-3">
                    <recess-card variant="variant1">
                        <div class="o-layout o-layout--small">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12">
                                        <h3 class="mb-4">{{ PROVIDER.ResultsAndContractsPage.ContractCostTypesPage.DetailsDescription }}</h3>
                                    </div>
                                    <div class="col-12">
                                        <div class="o-columns">
                                            <recess-checkbox-input
                                                v-for="(item, index) in selectedProvider.providerCostTypes"
                                                :key="`costType-${index}`"
                                                :default-checked="item.isDiscountable"
                                                :name="item.id"
                                                :label-text="item.costTypeDisplayValue"
                                                :disabled="true"
                                                variant="secondary"
                                                :class="[{'has-value': item.isDiscountable}, `o-column__item qa-cost-type-${index}`]"
                                                @input="(newValue) => setCostType(index, newValue)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </recess-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

const TitleText = () => import('@/components/atoms/TitleText')

export default {
    name: 'ProviderCostTypes',
    components: {
        TitleText
    },
    data() {
        return {
            BUTTON_TEXT,
            PROVIDER
        }
    },
    computed: {
        ...mapState('providerModule', ['selectedProvider'])
    }
}
</script>